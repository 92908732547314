import { createAction, props } from '@ngrx/store';
import { ReportFormInterface, BackendReport } from 'src/types';

// eslint-disable-next-line no-shadow
export const enum ReportActions {
  LoadShipReports = '[REPORT] Load Ship Reports',
  LoadShipReportsSuccess = '[REPORT] Load Ship Reports Success',
  LoadReportsFailure = '[REPORT] Load Reports Failure',
  LoadVoyageReports = '[REPORT] Load Voyage Reports',
  LoadVoyageReportsSuccess = '[REPORT] Load Voyage Reports Success',
  ResetReports = '[REPORT] Reset Reports',
  CreateReport = '[REPORT] Create Report',
  CreateReportSuccess = '[REPORT] Create Report Success',
  CreateReportFailure = '[REPORT] Create Report Failure',
  CreateMultipleReports = '[REPORT] Create Multiple Reports',
  CreateMultipleReportsSuccess = '[REPORT] Create Multiple Reports Success',
  CreateMultipleReportsFailure = '[REPORT] Create Multiple Reports Failure',
  UpdateReport = '[REPORT] Update Report',
  UpdateShipReport = '[REPORT] Update Ship Report',
  UpdateReportSuccess = '[REPORT] Update Report Success',
  UpdateShipReportSuccess = '[REPORT] Update Ship Report Success',
  UpdateReportFailure = '[REPORT] Update Report Failure',
  UpdateMultipleReports = '[REPORT] Update Multiple Reports',
  UpdateMultipleReportsSuccess = '[REPORT] Update Multiple Reports Success',
  UpdateMultipleReportsFailure = '[REPORT] Update Multiple Reports Failure',
  PromptDeleteReport = '[REPORT] Prompt Delete Report',
  PromptDeleteMultipleReports = '[REPORT] Prompt Delete Multiple Reports',
  DeleteReport = '[REPORT] Delete Report',
  DeleteReportSuccess = '[REPORT] Delete Report Success',
  DeleteReportFailure = '[REPORT] Delete Report Failure',
  DeleteMultipleReports = '[REPORT] Delete Multiple Reports',
  DeleteMultipleReportsSuccess = '[REPORT] Delete Multiple Reports Success',
  DeleteMultipleReportsFailure = '[REPORT] Delete Multiple Reports Failure',
  ToggleSelectReportId = '[REPORT] Toggle Select Report Id',
  ShowReportWarning = '[REPORT] Show Report Warning',
  SetAnomalyResolution = '[REPORT] Set Anomaly Resolution',
  SetAnomalyResolutionSuccess = '[REPORT] Set Anomaly Resolution Success',
  SetAnomalyResolutionFailure = '[REPORT] Set Anomaly Resolution Failure',
  UpdateImportStatus = '[REPORT] Update Import Status',
  ClearImportStatus = '[REPORT] Clear Import Status',
  ClearReportStatus = '[REPORT] Clear Report Status',
  ClearMultipleReportsStatus = '[REPORT] Clear Multiple Reports Status',
}

/** Load reports */
export const loadShipReports = createAction(
  ReportActions.LoadShipReports,
  props<{ imo: number; showAnomalies?: boolean }>()
);
export const loadShipReportsSuccess = createAction(
  ReportActions.LoadShipReportsSuccess,
  props<{ reports: BackendReport[] }>()
);
export const loadReportsFailure = createAction(ReportActions.LoadReportsFailure, props<{ error: any }>());

export const loadVoyageReports = createAction(ReportActions.LoadVoyageReports, props<{ uuid: string }>());
export const loadVoyageReportsSuccess = createAction(
  ReportActions.LoadVoyageReportsSuccess,
  props<{ reports: BackendReport[] }>()
);

/** Reset reports */
export const resetReports = createAction(ReportActions.ResetReports);

/** Create report */
export const createReport = createAction(
  ReportActions.CreateReport,
  props<{
    report: ReportFormInterface;
    imo: number;
    voyageUuid?: string;
    source?: 'siglar-reports-admin' | 'csv-import';
  }>()
);
export const createReportSuccess = createAction(
  ReportActions.CreateReportSuccess,
  props<{ response: any; imo: number; voyageUuid?: string }>()
);
export const createReportFailure = createAction(ReportActions.CreateReportFailure, props<{ error: any }>());

export const clearReportStatus = createAction(ReportActions.ClearReportStatus);

/** Create multiple report */
export const createMultipleReports = createAction(
  ReportActions.CreateMultipleReports,
  props<{ reports: Partial<ReportFormInterface>[]; imo: number; voyageUuid?: string }>()
);
export const createMultipleReportsSuccess = createAction(
  ReportActions.CreateMultipleReportsSuccess,
  props<{ responses: any; imo: number; voyageUuid?: string }>()
);
export const createMultipleReportsFailure = createAction(
  ReportActions.CreateMultipleReportsFailure,
  props<{ error: any }>()
);

export const clearImportStatus = createAction(ReportActions.ClearImportStatus);

export const clearMultipleReportsStatus = createAction(ReportActions.ClearMultipleReportsStatus);

/** Update report */
export const updateReport = createAction(
  ReportActions.UpdateReport,
  props<{ report: ReportFormInterface; imo: number }>()
);
export const updateReportSuccess = createAction(
  ReportActions.UpdateReportSuccess,
  props<{ report: Partial<BackendReport>; imo: number }>()
);
export const updateReportFailure = createAction(ReportActions.UpdateReportFailure, props<{ error: any }>());

export const setAnomalyResolution = createAction(
  ReportActions.SetAnomalyResolution,
  props<{ data: { resolved: boolean; recommendation: string }; anomalyId: string }>()
);

export const setAnomalyResolutionSuccess = createAction(
  ReportActions.SetAnomalyResolutionSuccess,
  props<{ data: { resolved: boolean; recommendation: string }; anomalyId: string }>()
);

export const setAnomalyResolutionFailure = createAction(
  ReportActions.SetAnomalyResolutionFailure,
  props<{ error: any }>()
);

export const updateShipReport = createAction(
  ReportActions.UpdateShipReport,
  props<{ report: ReportFormInterface; imo: number }>()
);
export const updateShipReportSuccess = createAction(
  ReportActions.UpdateShipReportSuccess,
  props<{ imo: number; showAnomalies?: boolean }>()
);

/** Update multiple report */
export const updateMultipleReports = createAction(
  ReportActions.UpdateMultipleReports,
  props<{ reports: ReportFormInterface[]; imo: number; voyageUuid?: string }>()
);
export const updateMultipleReportsSuccess = createAction(
  ReportActions.UpdateMultipleReportsSuccess,
  props<{ responses: any; imo: number; voyageUuid?: string }>()
);
export const updateMultipleReportsFailure = createAction(
  ReportActions.UpdateMultipleReportsFailure,
  props<{ error: any }>()
);

/** Delete report */
export const deleteReport = createAction(ReportActions.DeleteReport, props<{ id: number; imo: number }>());
export const deleteReportSuccess = createAction(
  ReportActions.DeleteReportSuccess,
  props<{ response: any; id: number; imo: number }>()
);
export const deleteReportFailure = createAction(ReportActions.DeleteReportFailure, props<{ error: any }>());

/** Delete multiple reports */
export const deleteMultipleReports = createAction(
  ReportActions.DeleteMultipleReports,
  props<{ ids: number[]; imo: number }>()
);
export const deleteMultipleReportsSuccess = createAction(
  ReportActions.DeleteMultipleReportsSuccess,
  props<{ ids: number[]; imo: number }>()
);
export const deleteMultipleReportsFailure = createAction(
  ReportActions.DeleteMultipleReportsFailure,
  props<{ error: any }>()
);

/** Toggle Select Reprot Id */
export const toggleSelectReportId = createAction(
  ReportActions.ToggleSelectReportId,
  props<{ id: number; checked: boolean }>()
);

/** Prompt delete report */
export const promptDeleteReport = createAction(
  ReportActions.PromptDeleteReport,
  props<{ report: ReportFormInterface; imo: number }>()
);

export const promptDeleteMultipleReports = createAction(
  ReportActions.PromptDeleteMultipleReports,
  props<{ ids: number[]; imo: number }>()
);

/** Report Warnings */
export const showReportWarning = createAction(
  ReportActions.ShowReportWarning,
  props<{ key: string; value: any; date: string }>()
);
