/* eslint-disable indent */
import { createSelector } from '@ngrx/store';
import moment from 'moment';
import { environment } from 'src/environments/environment';
import {
  AssociatedVoyage,
  Report,
  ShipUuid,
  VoyageItem,
  VoyageAnalysisSelector,
  PortActivity,
  ReportType,
  Company,
  ShipCondition,
  ReportFormInterface,
  Bunker,
  Anomaly,
} from 'src/types';
import {
  QaLabel,
  compareDate,
  compareNumber,
  convertToReportForm,
  formatMultilineActivityLabel,
  formatQaLabel,
  getPort,
  getPortName,
  getVoyageStatus,
  slugify,
  typedKeys,
  voyageItem,
  voyageList,
} from 'src/utils';
import { AppState } from '..';
import { selectCompanies, selectCompanyState } from './company.selectors';
import { selectReports, selectVoyageReports } from './report.selectors';
import { selectShipState, selectShipUuids } from './ship.selectors';
import { selectPorts } from './port.selectors';

export const selectVoyageState = (state: AppState) => state.voyages;

export const selectVoyages = createSelector(selectVoyageState, ({ entries = [] }) => entries.map(({ voyage }) => voyage));
export const selectVoyageSummary = createSelector(selectVoyageState, ({ voyageSummary }) => voyageSummary);
export const selectVoyageQuerySize = createSelector(selectVoyageState, ({ currentQuerySize }) => currentQuerySize);
export const selectVoyageLoading = createSelector(selectVoyageState, ({ loading }) => loading);
export const selectVoyageFilters = createSelector(selectVoyageState, ({ filters }) => filters);

export const selectCheckedVoyageUuids = createSelector(selectVoyageState, ({ checkedVoyageUuids }) => checkedVoyageUuids);

export const selectCurrentFilters = createSelector(
  selectVoyageState,
  ({ filter, filters, filterTags, filterByCompanyId }) => ({
    filter,
    filters,
    filterTags,
    filterByCompanyId,
  }),
);

export const selectAllVoyageItems = createSelector(
  selectVoyageState,
  selectShipState,
  selectCompanyState,
  ({ entries: voyageEntries = [] }, { shipUuids }, { entries: companies }) =>
    voyageEntries.map((voyage) => voyageItem(voyage, companies, shipUuids)),
);

export const selectVoyageListItem = createSelector(
  selectVoyageState,
  selectShipState,
  ({ voyageList: list = [], anomalies }, { shipUuids = [] }) =>
    list.map((voyage) => voyageList(voyage, shipUuids, anomalies)),
);

export const selectCurrentVoyageDetails = createSelector(
  selectVoyageState,
  selectVoyageReports,
  selectPorts,
  selectCompanies,
  selectShipUuids,
  ({ currentVoyageDetails }, reports, ports, companies, shipUuids) => {
    if (!currentVoyageDetails) {
      return null;
    }

    const {
      emissionsHistogram,
      geojson: initialGeoJson,
      status: { totalReports },
      summary: {
        distances: { ais: aisDistanceNm, reference: totalDistanceNm },
        distancesPerCondition,
        efficiency: {
          reference: { co2perTonMileG: totalEfficiency },
        },
        totalEmissionsCO2MT: totalEmissionsMt,
      },
      positions,
      report: { ship },
      voyage: {
        uuid: voyageUUID,
        charterpartyDate: longDate,
        startReportId,
        endReportId,
        companyUuid,
        tags,
        verified: rawVerified,
        idCharterer,
        ...analysisVoyage
      },
      voyageLegs: initialVoyageLegs,
      qa: { errors: topLevelErrors, warnings: topLevelWarnings },
    } = currentVoyageDetails;
    const { secondBallastLegNoEndReport } = topLevelWarnings;

    const verified = rawVerified === '0001-01-01T00:00:00Z' ? null : new Date(rawVerified);

    const voyageLegs = initialVoyageLegs || [];
    const hasEndReport = !!endReportId;
    const hasStartReport = !!startReportId;
    const isAssumedEnded = !!startReportId && secondBallastLegNoEndReport && !endReportId;

    const status = getVoyageStatus({ tags, hasStartReport, hasEndReport, isAssumedEnded } as VoyageItem, !!verified);

    /** DATASETS */
    const emissionDatasetData = voyageLegs.map(({ computed }) => computed.totalEmissionsCO2MT || 0);
    const distanceDatasetData = voyageLegs.map(({ distances: { ais = 0, ...distances } }) => ({
      ...distances,
      ais,
    }));

    const cargoDatasetData = voyageLegs.reduce(
      (accum, { cargoQuantityOnboardMT = 0 }) => [...accum, cargoQuantityOnboardMT],
      [],
    );

    let lastKnownReportType: ReportType;
    const datasetLabels = voyageLegs?.map(({ type: legType, condition, inPort, atSea, reports: voyageLegReports }) => {
      const portMovement = [];
      const nonNoonReports = voyageLegReports ? voyageLegReports.filter((report) => report.reportType !== 'noon') : [];

      if (nonNoonReports.length === 1) {
        const movement = `${lastKnownReportType} - ${nonNoonReports[0].reportType}`.toUpperCase().replace('_', ' ');
        portMovement.push(movement);
        lastKnownReportType = nonNoonReports[0].reportType;
      } else {
        for (let index = 1; index < nonNoonReports.length; index++) {
          const movement = `${nonNoonReports[index - 1].reportType} - ${nonNoonReports[index].reportType}`
            .toUpperCase()
            .replace('_', ' ');
          portMovement.push(movement);
          lastKnownReportType = nonNoonReports[index].reportType;
        }
      }

      if (legType === 'in_port' || legType === 'in_port_area') {
        const { portActivity, port: portCode, portIntention } = inPort;
        const activity: PortActivity =
          portActivity === 'W_M_before' && portIntention !== 'other' ? `awaiting_${portIntention}` : portActivity;
        const portName = getPortName(ports, portCode);
        return formatMultilineActivityLabel(activity, portMovement, portName);
      } else {
        const { activity, fromPort: portCode } = atSea;
        const { portIntention } = inPort;
        const shipActivity: PortActivity | ShipCondition =
          portIntention && portIntention !== 'other'
            ? `awaiting_${portIntention}`
            : !activity || activity === 'sailing'
              ? condition
              : activity;
        const portName = getPortName(ports, portCode);
        return formatMultilineActivityLabel(shipActivity, portMovement, portName);
      }
    });

    const startReport = reports.find(({ id }) => id === startReportId);
    const endReport = reports.find(({ id }) => id === endReportId);

    const totalCargoMt = startReport
      ? voyageLegs
          .reduce(
            (largest, { cargoQuantityOnboardMT = 0 }) =>
              cargoQuantityOnboardMT > largest ? cargoQuantityOnboardMT : largest,
            0,
          )
          .toFixed(3)
      : 0;

    const charterpartyDate = String(longDate).slice(0, 10);

    const charterer = companyUuid ? companies.find(({ uuid }) => uuid === companyUuid) : null;

    const ballastPort = startReport && getPort(ports, startReport?.port);
    const finalDischargePort = endReport && getPort(ports, endReport?.port);

    const initialReport = { portName: ballastPort?.name, reportDate: startReport?.reportedTimeLocal };
    const finalReport = { portName: finalDischargePort?.name, reportDate: endReport?.reportedTimeLocal };

    const geojson = {
      type: 'FeatureCollection',
      features: initialGeoJson.features
        ? initialGeoJson.features.map(({ geometry, properties, ...rest }: any) => ({
            geometry: { coordinates: geometry.coordinates, ...geometry },
            properties: { speed: properties.Speed, color: 'yellow' },
            ...rest,
          }))
        : [],
    };

    const reducedTopLevelErrors = typedKeys(topLevelErrors).reduce<string[]>(
      (allErrs, key) => (topLevelErrors[key] ? [...allErrs, key] : allErrs),
      [],
    );

    const reducedVoyageLegsErrors = voyageLegs.reduce((allErrs, vleg) => {
      const errors = vleg.qa.errors;
      const trueErrors = typedKeys(errors).filter((key) => errors[key]);
      return [...allErrs, ...trueErrors];
    }, []);

    const allErrors: any = [...reducedTopLevelErrors, ...new Set(reducedVoyageLegsErrors)];

    const reducedTopLevelWarnings = typedKeys(topLevelWarnings).reduce<string[]>(
      (allWarns, key) => (topLevelWarnings[key] ? [...allWarns, key] : allWarns),
      [],
    );

    const reducedVoyageLegsWarnings = voyageLegs
      ? voyageLegs.reduce((allWarns, { qa: { warnings } }) => {
          const trueKeys = typedKeys(warnings).filter((key) => warnings[key]);
          return [...allWarns, ...trueKeys];
        }, [])
      : [];

    let reportWarnings: string[] = [];
    let reportAnomalies: Anomaly[] = [];

    reports.forEach(({ reportType, externalAttributes, reportedTime, qa, anomalies = [] }) => {
      const warnings = Object.keys(qa?.warnings);
      if (externalAttributes?.knutsenLoadingDischargingWarning) {
        const warning = `${reportType
          .toUpperCase()
          .replace('_', ' ')} report at ${reportedTime} has a loading/discharging warning`;
        reportWarnings.push(warning);
      }

      reportWarnings = [...reportWarnings, ...warnings];
      reportAnomalies = [...reportAnomalies, ...anomalies];
    });

    const allAnomalies = reportAnomalies
      .filter(({ resolved }) => !resolved)
      .reduce((acc: any, item) => {
        acc[item.anomalyTypeId] = (acc[item.anomalyTypeId] || 0) + 1;
        return acc;
      }, {});

    const allWarnings = [...reducedTopLevelWarnings, ...new Set([...reducedVoyageLegsWarnings, ...reportWarnings])];
    const { name: shipName, imo: shipImo } = ship;

    const {
      uuid: shipUuid,
      ciiCfShipType,
      ciiDischargePumpType,
    } = (shipUuids && shipUuids.find(({ imo: entryImo }) => shipImo === entryImo)) || ({} as ShipUuid);

    const reportLinkSearchParams = new URLSearchParams({
      n: slugify(shipName),
    });

    if (idCharterer) {
      reportLinkSearchParams.append('cpid', idCharterer);
    }

    const { href: reportLink } = new URL(
      `${shipUuid}?${reportLinkSearchParams.toString()}`,
      environment.shipReportApp.url,
    );

    const { href: reportDemoLink } = new URL(
      `${shipUuid}?${reportLinkSearchParams.toString()}`,
      environment.shipReportApp.demoUrl,
    );

    const { href: emissionStatementDownloadLink } = verified
      ? new URL(`api/v1/emissions_statements/${voyageUUID}/download`, environment.siglarApi.url)
      : { href: null };

    const { href: euaStatementDownloadLink } =
      verified && moment(endReport?.reportedTimeLocal).isSameOrAfter(moment(new Date('1/1/2024')))
        ? new URL(`api/v1/eua_statements/${voyageUUID}/download`, environment.siglarApi.url)
        : { href: null };

    const brokerName = tags?.find((tag) => tag?.includes('brokerName:'))?.split(':')[1];
    const businessUnit = tags?.find((tag) => tag?.includes('businessUnit:'))?.split(':')[1];
    const counterPart = tags?.find((tag) => tag?.includes('counterPart:'))?.split(':')[1];
    reportLinkSearchParams.append('debug', 'true');
    const { href: reportDebugLink } = new URL(
      `${shipUuid}?${reportLinkSearchParams.toString()}`,
      environment.shipReportApp.url,
    );

    const voyagePorts = reports
      .filter(({ port, reportedTimeLocal }) => {
        const endDate = endReport ? moment(endReport.reportedTimeLocal) : moment(new Date());
        const startDate = startReport && moment(startReport.reportedTimeLocal);
        const isCurrentVoyageReport = startReport && moment(reportedTimeLocal).isBetween(startDate, endDate);
        return isCurrentVoyageReport && port?.length && port !== 'XXXXX';
      })
      .map(({ port }) => getPort(ports, port));

    const ballastDeviation =
      (distancesPerCondition.ballast.reported / distancesPerCondition.ballast.reference - 1) * 100 || 0;

    const ladenDeviation =
      (distancesPerCondition.laden.reported / distancesPerCondition.laden.reference - 1) * 100 || 0;
    const distanceDeviation = {
      ballastDeviation: ballastDeviation.toFixed(1),
      ladenDeviation: ladenDeviation.toFixed(1),
    };

    const hasUnknownPorts = datasetLabels.find((label) => label[0].toLowerCase().includes('unknown'));
    const verificationErrors = allErrors.map((error: keyof typeof QaLabel) => formatQaLabel(error));

    if (!['verified', 'completed'].includes(status)) {
      verificationErrors.push('Voyage not completed');
    }
    if (!ship.shipType) {
      verificationErrors.push('Ship type not set');
    }
    if (hasUnknownPorts) {
      verificationErrors.push('Voyage has unknown ports');
    }

    return {
      status,
      emissionsHistogram,
      reports: { entries: reports, errors: topLevelErrors, warnings: topLevelWarnings },
      ship,
      summary: {
        aisDistanceNm,
        totalCargoMt,
        totalDistanceNm,
        totalEmissionsMt,
        totalReports,
        totalEfficiency,
        distancesPerCondition,
        distanceDeviation,
      },
      voyage: {
        uuid: voyageUUID,
        companyUuid,
        charterer,
        charterpartyDate,
        initialReport,
        finalReport,
        startReportId,
        endReportId,
        idCharterer,
        brokerName,
        businessUnit,
        counterPart,
        tags,
        verified,
        reportLink,
        reportDemoLink,
        reportDebugLink,
        emissionStatementDownloadLink,
        euaStatementDownloadLink,
        ballastPort,
        finalDischargePort,
        ...analysisVoyage,
      },
      voyageLegsSummary: {
        emissionDatasetData,
        distanceDatasetData,
        cargoDatasetData,
        datasetLabels,
      },
      voyageLegs,
      geojson,
      verified,
      cii: {
        shipType: ciiCfShipType,
        pumpType: ciiDischargePumpType,
        deadWeightTon: ship.deadWeightTon,
      },
      positions,
      warnings: allWarnings,
      errors: allErrors,
      anomalies: Object.keys(allAnomalies).length ? allAnomalies : null,
      voyagePorts,
      verificationErrors,
    } as VoyageAnalysisSelector;
  },
);

export const selectCurrentVoyageComments = createSelector(selectVoyageState, ({ currentVoyageComments }) =>
  currentVoyageComments?.length ? currentVoyageComments : []
);

export const selectVoyageIdCharterers = createSelector(selectVoyageState, ({ entries }) =>
  entries.map(({ voyage: { companyUuid, idCharterer, uuid } }) => ({
    companyUuid,
    idCharterer,
    uuid,
  }))
);

export const selectShipVoyages = createSelector(
  selectVoyageState,
  selectReports,
  selectCompanyState,
  ({ shipVoyages = [] }, currentReports, companies) => {
    const reports = currentReports.reduce((map, report) => {
      map.set(report.id, report);
      return map;
    }, new Map<number, Report>());

    const voyageRows = [...shipVoyages]
      .sort((a, b) => compareDate(new Date(a.charterpartyDate), new Date(b.charterpartyDate), false))
      .map(
        ({
          uuid,
          charterpartyDate,
          idCharterer: charterpartyId,
          assignedTo: responsible,
          companyUuid,
          startReportId,
          endReportId,
        }) => {
          const associatedReports: Report[] = [];
          const startReport = reports?.get(startReportId);
          const endReport = reports?.get(endReportId);

          const charterer = companyUuid ? companies?.entries?.find(({ uuid: companyId }) => companyId === companyUuid) : null;

          if (!(startReport && endReport)) {
            return {
              uuid,
              charterpartyDate,
              charterpartyId,
              responsible,
              charterer,
              totalEmissions: null,
              efficiency: null,
              sailedLadenDistance: null,
              reports: associatedReports,
              startDate: startReport ? new Date(startReport.reportedTime) : null,
              endDate: endReport ? new Date(endReport.reportedTime) : null,
            } as AssociatedVoyage;
          }

          const startTime = new Date(startReport.reportedTime).getTime();
          const endTime = new Date(endReport.reportedTime).getTime();
          for (const report of reports.values()) {
            const { reportedTimeLocal } = report;

            const reportedDate = new Date(reportedTimeLocal);
            const reportedTime = reportedDate.getTime();

            if (startTime <= reportedTime && endTime >= reportedTime) {
              associatedReports.push(report);
              reports.delete(report.id);
            }
          }

          return {
            uuid,
            charterpartyDate,
            charterpartyId,
            responsible,
            charterer,
            totalEmissions: null,
            efficiency: null,
            sailedLadenDistance: null,
            reports: associatedReports,
            startDate: new Date(startReport.reportedTime),
            endDate: new Date(endReport.reportedTime),
          } as AssociatedVoyage;
        }
      );

    const rogueReports: Report[] = [];

    for (const report of reports.values()) {
      rogueReports.push(report);
    }

    const entries = {
      rogueReports,
      voyageRows,
    };
    return entries;
  }
);

export const selectCompanyFilter = createSelector(
  selectCompanies,
  selectVoyageSummary,
  (companies, { companies: companySummary = [] }) =>
    companySummary
      .map((summary) => {
        const company: Company = companies.find(({ name }) => name === summary.name);
        return { ...summary, ...company, count: summary.voyages };
      })
      .sort((a, b) => compareNumber(a.count, b.count, false))
);

export const selectVoyageStatus = createSelector(selectVoyageSummary, ({ voyages }) => {
  const status = voyages && Object.keys(voyages);
  const statusFilter = status
    ?.map((filter) => ({
      id: filter,
      label: filter.replace(/([A-Z])/g, ' $1').replace(/\b\w/g, (match) => match.toUpperCase()),
      count: voyages[filter],
    }))
    .filter(({ count }) => !!count)
    .filter(({ id }) => id !== 'notCancelled');

  return statusFilter || [];
});

export const selectReportFormById = (reportId: string | number) =>
  createSelector(selectReports, selectPorts, selectCurrentVoyageDetails, (reports, ports, currentVoyage) => {
    const cii = currentVoyage?.cii;
    const isVerified = currentVoyage?.status === 'verified';
    const report = reports && reports.find(({ id }) => id === Number(reportId));
    if (!report) {
      if (!reports.length) {
        return { id: 'new', isVerified, cii } as ReportFormInterface;
      }
      const lastReport = reports[reports.length - 1];
      const timezone =
        lastReport.utcOffset.charAt(0) === '-'
          ? lastReport.utcOffset.slice(0, -3)
          : `+${lastReport.utcOffset.slice(0, -3)}`;
      const bunkers = Object.values(lastReport.bunkers).map((bunker: Bunker) => ({
        grade: bunker.grade,
        remainingOnBoardMT: bunker.remainingOnBoardMT,
        consumptionMT: 0,
        receivedMT: 0,
        calculatedConsumptionMT: 0,
        previousROB: bunker.remainingOnBoardMT,
        previousCons: bunker.consumptionMT,
        previousRcv: bunker.receivedMT,
      }));
      return {
        id: 'new',
        timezone,
        bunkers,
        isVerified,
        cii,
        remark: lastReport.remark,
        internalRemark: lastReport.internalRemark,
      } as ReportFormInterface;
    }
    return convertToReportForm({ ...report, isVerified, cii }, ports);
  });
